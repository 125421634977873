import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import axios from "axios";

import Layout from "../components/layout"
import Seo from "../components-tr/seo"

const description = "tio D2C markalara daha iyi dijital deneyimler ve teknoloji destekli çözümler sağlar."

const MeetPage = () => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
    text: "init"
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
      text: `Teşekkürler!`
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true, text: "Yükleniyor..." });
    axios({
      method: "post",
      url: "https://getform.io/f/0c15c4fc-a0b5-4e2c-b024-fca05475e590",
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "success", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <Layout navbarTheme="dark" className="bg-cream">
      <Seo title="Meet Tio" description={description} canonical="https://tio.ist/tr/meet-tio/" />
      <div className=" relative p-10 lg:py-20">

        <div className="absolute -top-10 lg:top-0 right-0 lg:right-48">
          <StaticImage quality="100" className="" src="../images/d2c-markalar.png" width={150} alt="D2C Markalar" />
        </div>
        <div className="absolute max-w-max invisible lg:bottom-20 lg:visible left-0 lg:right-20">
          <StaticImage className="" src="../images/sol-ok.svg" width={350} alt="Tio" />
        </div>
        <div className="container text-xl lg:text-3xl text-center font-light mx-auto max-w-2xl">
          <h1 className="text-4xl lg:text-6xl px-10 mb-5 mt-10 lg:mt-0 lg:mb-20 font-bold text-blue">
            Markalara dijital altyapı sağlıyoruz.
          </h1>
          <p >
            Kendi ürünlerini üreten, dijital görünürlüğüne önem veren, müşterilerine başarılı bir <b className="font-bold">dijital deneyim</b> sunmak isteyen markalar arıyoruz.
          </p>
          <Link to="#meet-tio" className="underline mt-20 text-blue text-xl block">Tio Dijital Çözüm Ekibi ile tanışın.</Link>
        </div>
      </div>
      <div className=" relative p-10 py-20">
        <div className="absolute max-w-max invisible lg:visible z-1 -top-10 lg:top-32 right-0 lg:right-0">
          <StaticImage className="" src="../images/right-circle.svg" width={200} alt="Tio" />
        </div>
        <div className="absolute z-1 lg:p-0 -top:10 lg:top-48  left-32 lg:right-20">
          <StaticImage className="" src="../images/is-buyut.png" width={100} alt="Tio ile işinizi büyütün" />
        </div>
        <div className="container mt-32 lg:mt-0 text-xl lg:text-2xl text-left lg:text-center font-light mx-auto max-w-xl">
          <h2 className="text-4xl lg:text-6xl  py-10 font-bold text-blue">İşinizi dijitalde <br />tio ile büyütün.</h2>
          <p className=" pb-5">
            Tio işletmenizdeki <b className="font-bold">üretim, satış, sipariş, teslimat ve stok takip</b> süreçlerini dijitalde kolayca yönetebilmeniz için gerekli araç ve hizmetleri sağlar.
          </p>
          <p className=" pb-5">
            Teslimat yönetimi, satış yönetimi, barkodlu satış, e-fatura gibi süreçlerinde daha iyi bir dijital akışı hedefleyen işletmelerle <b className="font-bold">dijital partnerlik</b> yapıyoruz.
          </p>
          <p className=" ">
            Yalnızca yazılımlarımızla değil, markanız için değer yaratan <b className="text-blue font-bold">tio ekibi</b> ile yanınızdayız.
          </p>
          <div className="block my-20 text-xl">
            <Link to="#meet-tio" className="block lg:inline underline py-5 text-blue">Hemen arayın →</Link>
            <Link to="#meet-tio" className="block lg:inline bg-blue text-white p-2 lg:m-5">İşinizi tio ile büyütün!</Link>
          </div>
        </div>
      </div>

      <div className=" relative p-10 py-20">
        <div className="lg:absolute max-w-max z-1 -top-5 left-0 lg:right-0 ">
          <StaticImage className="" src="../images/tio-boost.png" width={430} alt="Tio ile işinizi büyütün" />
        </div>
        <div className="container text-xl lg:text-2xl text-left lg:pl-20 font-light mx-auto max-w-3xl">
          <h2 className="text-6xl py-10 font-bold text-blue">Dijital partneriniz olmak istiyoruz.</h2>
          <p className=" pb-5">
            Dilediğiniz Kanaldan Satış Yapın, Tek Platformdan Yönetin: Sipariş, teslimat, kurye ve e-faturalarınızı tek bir arayüzden <b className="font-bold">10 kat daha hızlı</b> yönetin. Canlı veri analitiği ile işinizi büyütün.
          </p>
          <p className=" pb-5">
            İşinizi tek bir dijital platformda yönetmek için ihtiyacınız olan tüm araçlar bir arada.
          </p>
          <p className=" ">
            Yalnızca yazılımlarımızla değil, markanız için değer yaratan <b className="text-blue font-bold">tio ekibi</b> ile yanınızdayız.
          </p>
          <div className="block my-20 text-xl">
            <Link to="#meet-tio" className="underline py-10 text-blue">Tio Boost Randevunu Al</Link>
            <p className="block max-w-sm text-lg py-5">( 🚀 Tio Boost işinizi dijitalde nasıl daha kolay yürütebileceğinizi konuştuğumuz 20 dakikalık bir görüşmedir. )</p>
          </div>
        </div>
      </div>

      <div className=" relative p-10 py-20 mt-20 lg:mt-0">
        <div className="absolute z-1 -right-20 -top-48 lg:-top-32 lg:right-0">
          <StaticImage className="" src="../images/tio-light.svg" width={430} alt="Tio ile işinizi büyütün" />
        </div>
        <div className="container mt-20 text-xl lg:px-32">
          <div className="lg:flex">
            <div className="lg:w-1/2 lg:p-10 relative">
              <h2 className="text-5xl max-w-xs font-bold text-blue ">Tio Dijital Çözüm Ekibi ile Tanışın!</h2>
              <div className="hidden lg:block">
                <Link to="#meet-tio" className="py-10 block max-w-xs underline text-blue">Dijital akışınızı tio çözümleri ile pürüzsüz hale getirin. ↗</Link>
                <div className="w-1/2 text-center block p-10">
                  <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Tio ile işinizi büyütün" />
                </div>
                <div className="w-1/2 text-center block p-10">
                  <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Tio ile işinizi büyütün" />
                </div>
              </div>
            </div>

            <ul className="list-disc max-w-xl p-10 font-light">
              <li className="py-3">Ekibimiz iş süreçlerinizi analiz eder.</li>
              <li className="py-3">İşlerinizi <b className="font-bold">daha iyi</b> yönetebilmeniz için hangi dijital çözümlerin uygulanabileceği belirlenir ve uygulama için <b className="font-bold">yol haritası</b> çıkarılır.</li>
              <li className="py-3">İhtiyacınız olan çözümlerin işletmenizde nasıl uygulanacağına birlikte karar veririz.</li>
              <li className="py-3">1 hafta içinde iyileştirilmiş sisteminizi kullanmaya başlayabilirsiniz.<span className="text-pink">*</span>
                <p className="text-md mt-10 font-italic" ><span className="text-pink">*</span> Kullanılan yazılım, aktarılan verinin büyüklüğü ve yapısına göre değişkenlik gösterilir.</p>

              </li>
            </ul>
          </div>
          <div id="meet-tio" className="text-center p-10">
            <div className="lg:hidden relative">
              <Link to="#meet-tio" className="py-10 block max-w-xs underline text-blue">Dijital akışınızı tio çözümleri ile pürüzsüz hale getirin. ↗</Link>
              <div className="lg:w-1/2 text-center block p-10">
                <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Tio ile işinizi büyütün" />
              </div>
              <div className="lg:w-1/2 text-center block p-10">
                <StaticImage className="" src="../images/down-arrow.svg" width={50} alt="Tio ile işinizi büyütün" />
              </div>
            </div>
            {!serverState.status?.ok ?
              <form name="meet" id="form" method="post" onSubmit={handleOnSubmit} >
                <input type="hidden" name="form-name" value="meet" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="name" placeholder="İsim Soyisim *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="companyName" placeholder="Şirket Adı *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="email" name="email" placeholder="E-posta adresiniz *" />
                <input className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required type="text" name="phone" placeholder="Telefon Numarası *" />
                <select className="bg-white max-w-sm w-full border border-blue p-3" name="companySize">
                  <option disabled selected>Şirket Büyüklüğü</option>
                  <option value="1-9">1-9 Çalışan</option>
                  <option value="10-49">1-49 Çalışan</option>
                  <option value="50-249">50-249 Çalışan</option>
                  <option value="250+">250+ Çalışan</option>
                </select>
                <textarea className="block bg-white rounded outline-none focus:border-yellow mx-auto p-3 border text-blue my-2 max-w-sm w-full border-blue" required rows="5" name="message" placeholder="Mesajınız">
                </textarea>
                <button className="bg-blue px-5 rounded mt-5 py-2 text-white" type="submit" >Gönder</button>
              </form> :
              <div className="max-w-sm w-full mx-auto">
                <p className="text-xl lg:text-2xl text-center">{serverState.text}</p>
              </div>
            }
          </div>
        </div>
      </div>


    </Layout>
  )
}
export default MeetPage
